* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --clr-primary-100: #0a5dc2;
  --clr-primary-900: #3a0ca3;
  --clr-white-100: #ffffff;
  --clr-white-200: #f2f3f7;
  --clr-grey-100: #c4c4c4;
  --clr-grey-200: #a6a6a6;
  --clr-grey-300: #404040;
  --clr-grey-400: #70707c;
  --clr-grey-500: #2e2e3a;
  --clr-black: #000;
  --clr-success: #32ab45;
  --clr-success-bg: rgba(28, 167, 139, 0.2);
  --clr-purple: #7209b7;
  --clr-pink: #f72585;
  --clr-sky-blue: #4cc9f0;
  --clr-dark-blue: #3a0ca3;
   --shadow-dark-color: #d3dae7;
  --shadow-light-color: #fff;
  --box-shadow: 1rem 1rem 1rem var(--shadow-dark-color),
  -1rem -1rem 1rem var(--shadow-light-color);

  --box-shadow-2: 0rem 0rem 0rem var(--shadow-dark-color),
  -.3rem -.3rem 1rem var(--shadow-dark-color)

  --fw-regular: 400;
  --fw-semi-bold: 500;
  --fw-bold: 600;
  --fw-very-bold: 700;

  --fs-300: 0.8125rem;
  --fs-400: 0.875rem;
  --fs-500: 0.9375rem;
  --fs-600: 1rem;
  --fs-700: 1.875rem;
  --fs-800: 2.5rem;
  --fs-900: 3.5rem;

  --size-100: 0.25rem;
  --size-200: 0.5rem;
  --size-300: 0.875rem;
  --size-400: 1rem;
  --size-450: 1.375rem;
  --size-500: 1.5rem;
  --size-600: 2rem;
  --size-700: 2.5rem;
  --size-800: 3rem;
  --size-900: 4rem;
  --size-1000: 5rem;
}

.small_black_text {
  color: var(--clr-black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.flex-centered-items {
  display: flex;
  align-items: center;
  gap: 10px;
  /* overflow-x: auto; */
}

.active-status {
  color: var(--clr-success);
  font-size: 12px;
  font-weight: var(--fw-very-bold);
  line-height: 16px; /* 133.333% */
  display: flex;
  height: 26px;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--clr-success-bg);
  box-shadow: 0px 4px 4px 0px rgba(28, 167, 139, 0.07);
  width: 58px;
}

.flex-row{
  display: flex;
  gap: 10px;
}